import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as smoothscroll from 'smoothscroll-polyfill';

import { CommonEventsEnum, LocalStorageKeysEnum } from '@shared/enums';
import { MIN_ORDER_PRICE } from '@shared/configs';
import { RudderStackService } from '@shared/services';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit, AfterViewChecked {

  public step: number;
  public isScrolled: boolean;
  public MIN_ORDER_PRICE = MIN_ORDER_PRICE;

  constructor(
    private titleService: Title,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private rudderstack: RudderStackService,
  ) {
    smoothscroll.polyfill();
  }

  ngOnInit(): void {
    localStorage.setItem(LocalStorageKeysEnum.onboardingShowed, '1');

    this.isScrolled = true;
    this.step = 0;

    setTimeout(() => {
      this.step = 1;

      this.cdr.markForCheck();
    }, 1);
  }

  ngAfterViewChecked(): void {
    if (this.isScrolled) {
      return;
    }

    this.isScrolled = true;

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  public onNextClick(): void {
    this.isScrolled = false;
    this.step += 1;

    if (this.step >= 8) {
      this.router.navigate(['login']);
      return;
    }

    this.playSendSound();

    setTimeout(() => {
      this.isScrolled = false;
      this.step += 1;
      this.playRecieveSound();
      this.cdr.markForCheck();
    }, 800);
  }

  public playSendSound(): void {
    const audio = new Audio('/assets/sounds/message_send.mp3');
    audio.play();
  }

  public playRecieveSound(): void {
    const audio = new Audio('/assets/sounds/message_recieve.mp3');
    audio.play();
  }

  public nextBtnText(): string {
    if (this.step <= 2) {
      return 'Хочу подробнее';
    }

    if (this.step <= 4) {
      return 'Круто!';
    }

    if (this.step <= 6) {
      return 'А что в меню?';
    }

    return 'Поехали!';
  }

  public onSkipClick(): void {
    this.rudderstack.track(CommonEventsEnum.skipIntro);
    this.router.navigate(['login']);
  }

}
