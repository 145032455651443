<div class="onboarding-container" [ngClass]="step >= 1 ? 'loaded' : ''">
  <div class="step" [ngClass]="step >= 1 ? 'visible' : ''">
    <div class="msg msg-left">
      <p>Привет👋</p>
      <p>Это justfood. Мы готовим и доставляем здоровую еду.</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 2 ? 'visible' : ''">
    <div class="msg msg-right">
      <p>Хочу подробнее</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 3 ? 'visible' : ''">
    <div class="msg msg-left">
      <p>Наша еда выглядит так:</p>
    </div>

    <img class="food-img" src="/assets/images/welcome-food.jpg" title="Вкусная еда" />

    <div class="msg msg-left">
      <p>На этой страничке вы можете заказать готовые блюда с индивидуальным графиком доставки. Хоть на один день, хоть на неделю.</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 4 ? 'visible' : ''">
    <div class="msg msg-right">
      <p>Круто!</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 5 ? 'visible' : ''">
    <div class="msg msg-left">
      <p>На следующем шаге укажите данные для доставки. Они сохранятся, но их всегда можно будет поменять.</p>
      <p>Потом выберите дни доставки. Можно выбрать один или несколько.</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 6 ? 'visible' : ''">
    <div class="msg msg-right">
      <p>А что в меню?</p>
    </div>
  </div>

  <div class="step" [ngClass]="step >= 7 ? 'visible' : ''">
    <div class="msg msg-left">
      <p>На каждый день доставки — новое меню.</p>
      <p><img class="meal-type-img" src="/assets/images/premium.svg" title="Премиальное блюдо" /> так отмечены премиальные блюда,</p>
      <p><img class="meal-type-img" src="/assets/images/veg.svg" title="Вегетарианские блюдо" /> а так вегетарианские</p>
      <p>Минимальный заказ на одну доставку — {{ MIN_ORDER_PRICE }} руб. Срок годности наших блюд — 3-5 суток.</p>
    </div>
  </div>

  <div class="footer">
    <button type="button" class="btn btn-next" (click)="onNextClick()" [ngClass]="step >= 7 ? 'last-btn' : ''" [disabled]="step === 2 || step === 4 || step === 6">{{nextBtnText()}}</button>
    <button type="button" class="btn btn-white btn-skip" (click)="onSkipClick()" [hidden]="step >= 7">Все знаю, пропустить</button>
  </div>
</div>
